import React, { useContext } from "react";
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { AppContext } from "./context/appContext";
// import { renderRoutes } from 'react-router-config';
// import "./App.scss";

import { Dimmer, Loader, Modal, Button } from "semantic-ui-react";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));
const AdminLogin = React.lazy(() => import("./admin/views/Login/Login"));
const DashBoard = React.lazy(() => import("./admin/views/DashBoard/DashBoard"));
const TwoFactorAuth = React.lazy(() => import("./views/2FAOTP/TwoFactorAuth"));

const Register = React.lazy(() => import("./views/Register/index"));
const Page404 = React.lazy(() => import("./views/Page404"));
const Page500 = React.lazy(() => import("./views/Page500"));
const ForgotPasswd = React.lazy(() =>
  import("./views/ForgotPasswd/ForgotPasswd")
);

const BarcodeScan = React.lazy(() => import("./views/barcode/index"));
const BarcodeDropZone = React.lazy(() => import("./views/barcode/dropZone"));
const MarksClassContext = React.lazy(() => import("./views/Evaluation"));
const ViewEvaluation = React.lazy(() => import("./views/ViewEval"));
const VerticalEvaluation = React.lazy(() =>
  import("./views/Evaluation/VerticalEvaluation/VerticalEvaluation")
);

const App = () => {
  const { load, err_msg } = useContext(AppContext);
  const { confirmload, confirm_err_msg } = useContext(AppContext);
  const [loader, setLoader] = load;
  const [error, dispatch_err] = err_msg;
  const [confirm_error, dispatch_confirm_err] = confirm_err_msg;
  // setLoader(true);

  return (
    <div>
      <Dimmer active={loader} page>
        <Loader size="massive">Loading</Loader>
      </Dimmer>
      <Modal size={"mini"} open={error.open} dimmer="inverted">
        <Modal.Header style={{ backgroundColor: error.color, color: "#fff" }}>
          {error.header}
        </Modal.Header>
        <Modal.Content>
          <div dangerouslySetInnerHTML={{ __html: error.message }} />

          {/* <p>{error.message}</p> */}
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ backgroundColor: error.color }}
            positive
            onClick={() => dispatch_err({ type: "CLOSE_MSG_BOX" })}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size={"mini"} open={confirm_error.open} dimmer="inverted">
        <Modal.Header
          style={{ backgroundColor: confirm_error.color, color: "#fff" }}
        >
          {confirm_error.header}
        </Modal.Header>
        <Modal.Content>
          <div dangerouslySetInnerHTML={{ __html: confirm_error.message }} />

          {/* <p>{confirm_error.message}</p> */}
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            content="Red"
            onClick={() =>
              dispatch_confirm_err({ type: "CLOSE_CONFIRM_MSG_BOX" })
            }
          >
            CANCEL
          </Button>
          <Button
            basic
            color="blue"
            content="Blue"
            onClick={() => dispatch_confirm_err({ type: "OK_CONFIRM_MSG_BOX" })}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>

      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path={[
                "/bcu",
                "/rcub",
                "/dud",
                "/sdu",
                "/demo",
                "/gcg",
                "/srun",
                "/acu",
                "/dypatil",
                "/reva",
                "/msruas",
                "/kus",
                "/vskub",
                "/surana"
              ]}
              //path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path={[
                "/bcu/admin",
                "/rcub/admin",
                "/sdu/admin",
                "/dud/admin",
                "/demo/admin",
                "/gcg/admin",
                "/srun/admin",
                "/acu/admin",
                "/dypatil/admin",
                "/reva/admin",
                "/msruas/admin",
                "/kus/admin",
                "/vskub/admin",
                "/surana/admin",
              ]}
              name="Admin Login Page"
              render={(props) => <AdminLogin {...props} />}
            />

            <Route
              path="/admin"
              name="Admin Login Page"
              render={(props) => <DashBoard {...props} />}
            />

            <Route
              exact
              path="/twofa"
              name="Two Factor Auth"
              render={(props) => <TwoFactorAuth {...props} />}
            />

            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />

            <Route
              exact
              path="/forgot-passwd"
              name="Forgot Password"
              render={(props) => <ForgotPasswd {...props} />}
            />

            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />

            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/barcode"
              name="Barcode"
              render={(props) => <BarcodeScan {...props} />}
            />
            <Route
              exact
              path="/barcode-drop"
              name="Barcode Dropzone"
              render={(props) => <BarcodeDropZone {...props} />}
            />
            <Route
              exact
              path="/evaluate/:ifqpcode/:ifregcode/:ifvalno"
              name="Evaluation"
              render={(props) => <MarksClassContext {...props} />}
            />

            <Route
              exact
              path="/viewEval/:ifqpcode/:ifregcode/:ifvalno"
              name="View Evaluation"
              render={(props) => <ViewEvaluation {...props} />}
            />

            <Route
              path="/dashboard"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/verticalEvaluate/:qpcode/:regcode/:valno"
              name="Evaluation"
              render={(props) => <VerticalEvaluation {...props} />}
            />
            <Redirect from="/" to="/404" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </div>
  );
};

export default App;
